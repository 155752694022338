<template>
  <div class="lesson-thumb">
    <div v-if="style" class="thumb" :style="style" />

    <video
      v-else-if="isStream"
      crossorigin
      :id="'video-poster' + lesson.id"
      class="thumb"
    />

    <div v-if="lesson.is_blocked" class="block">
      <img
        src="https://img.icons8.com/material-outlined/56/ffffff/lock--v1.png"
      />
    </div>
  </div>
</template>

<script>
import Hls from "hls.js";
import isMobile from "ismobilejs";
import { youtubeParser } from "@/functions/helpers";

export default {
  props: {
    lesson: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    isStream() {
      return this.lesson.link_media && this.lesson.link_media.includes(".m3u8");
    },
    style() {
      let image = this.lesson.picture;

      if (
        !image &&
        this.lesson.link_media &&
        this.lesson.link_media.includes("youtu")
      ) {
        image = `https://i.ytimg.com/vi/${youtubeParser(
          this.lesson.link_media
        )}/hqdefault.jpg`;
      }

      return image ? `background-image: url(${image})` : null;
    }
  },
  mounted() {
    if (this.style || !this.isStream) {
      return;
    }

    const video = document.getElementById("video-poster" + this.lesson.id);
    const startTime = 10;

    if (
      video.canPlayType("application/vnd.apple.mpegURL") &&
      !isMobile(window.navigator).android.device
    ) {
      let source = document.createElement("source");
      source.src = this.lesson.link_media;
      source.type = "application/vnd.apple.mpegURL";
      video.appendChild(source);
      video.currentTime = startTime;
    } else if (Hls.isSupported()) {
      var config = {
        xhrSetup: function(xhr, url) {
          xhr.withCredentials = false;
        },
        autoStartLoad: false
      };

      const hls = new Hls(config);
      hls.loadSource(this.lesson.link_media);

      hls.on(Hls.Events.MANIFEST_PARSED, function() {
        hls.startLoad(startTime);
      });

      hls.attachMedia(video);
    }
  }
};
</script>

<style lang="scss" scoped>
.lesson-thumb {
  position: relative;
  height: 0;
  width: 100%;
  display: block;
  padding-bottom: 55%;
  background-color: black;
  margin-bottom: 8px;

  .thumb {
    background-size: cover;
    background-position-x: center;
    background-position-y: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .block {
    background: linear-gradient(#311a, #113a);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
